/**
 * Toggles the "checked" class, input checkbox state, and ARIA attribute for an element.
 *
 * @param {HTMLElement} el - The HTML element to toggle.
 */
export const toggleCheck = (el) => {
  // Toggle the "checked" class on the element.
  el.classList.toggle("checked");

  // Toggle the checked state of the input element within the given element.
  el.querySelector("input").checked = !el.querySelector("input").checked;

  // Update the "aria-selected" attribute based on the input checkbox state.
  el.setAttribute("aria-selected", !el.querySelector("input").checked);

  // Update the "selected" property on the srcElement (deprecated).
  el.srcElement.selected = !el.srcElement.selected;
};

/**
 * Creates a new HTML element with specified attributes and properties.
 *
 * @param {string} tag - The HTML tag for the new element.
 * @param {Object} params - An object containing attributes and properties to set on the element.
 * @returns {HTMLElement} - The newly created HTML element.
 */
export const newElement = (tag, params) => {
  let element = document.createElement(tag);
  if (params) {
    Object.keys(params).forEach((key) => {
      if (key === "class") {
        // Add CSS classes to the element.
        Array.isArray(params[key])
          ? params[key].forEach((o) =>
              o !== "" ? element.classList.add(o) : 0,
            )
          : params[key] !== ""
          ? element.classList.add(params[key])
          : 0;
      } else if (key === "style") {
        // Apply CSS styles to the element.
        Object.keys(params[key]).forEach((value) => {
          element.style[value] = params[key][value];
        });
      } else if (key === "text") {
        // Set innerHTML to a non-breaking space if text is empty; otherwise, set innerText.
        params[key] === ""
          ? (element.innerHTML = "&nbsp;")
          : (element.innerText = params[key]);
      } else if (key.includes("data-")) {
        // Set data attributes on the element.
        element.setAttribute(key, params[key]);
      } else {
        // Set other properties on the element.
        element[key] = params[key];
      }
    });
  }
  return element;
};
