import $ from 'jquery';

$("#announcement-banner-close").click(function () {
    var d = new Date();
    d.setTime(d.getTime() + 3600 * 1000 * 24 * 365 * 10);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "banner=true;" + expires + ";path=/";
    if ($(".announcement-banner").hasClass("fadeout"))
        $(".announcement-banner").removeClass("fadeout").addClass("fadein");
    else
        $(".announcement-banner").removeClass("fadein").addClass("fadeout");
});