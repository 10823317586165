const initCircleStat = (el) => {

    const setScale = (el, string) => {
        let calculatedScale = 1 - ((string.length - 5) * .05);
        //minimum boundary
        calculatedScale = calculatedScale < .4 ? .4 : calculatedScale;
        //maximum boundary 
        calculatedScale = calculatedScale > 1 ? 1 : calculatedScale;
        el.style = `--stat-scale:${calculatedScale}`;
    }


    const number = parseInt(el.getAttribute("data-number"));
     //if number is not a number show it but don't enable animation
    if (isNaN(number)) {
        el.innerText = el.getAttribute("data-number");
        setScale(el, el.getAttribute("data-number"))
        el.innerText = el.getAttribute("data-number").toLocaleString("en-US");
        el.setAttribute("data-disable-animation", true);
        return false;
    }

    //if reduced motion is enabled, disable animation
    if (window.matchMedia("(prefers-reduced-motion)").matches) {
        setScale(el, el.getAttribute("data-number").toLocaleString("en-US"))
        el.innerText = el.getAttribute("data-number").toLocaleString("en-US");
        el.setAttribute("data-disable-animation", true);
        return false;
    }
    //if number is a string just set the text
    if(isNaN(el.getAttribute('data-number'))){
        el.innerText = el.getAttribute('data-number');
    } else {
        el.innerText = (el.getAttribute('data-number') * .5).toLocaleString('en-US');
    };

    const speed = 500;

    const animate = () => {
         if(isNaN(el.getAttribute('data-number')))return;
        if (el.getAttribute("data-animated")) return;
        const value = +el.getAttribute('data-number');
        const data = +parseInt(el.innerText.replaceAll(",", "")) || el.getAttribute('data-number') * .5;

        const time = value / speed;
        if (data < value) {
            setScale(el, Math.ceil(data + time).toLocaleString('en-US'))
            el.innerText = Math.ceil(data + time).toLocaleString('en-US');
            setTimeout(animate, 1);
        } else {
            el.innerText = value.toLocaleString('en-US');
        }
    }

    el.animate = animate;
}




document.addEventListener("DOMContentLoaded", function () {
    const intersectionHandler = (entries, observer) => {
         entries.map((entry) => {
             if (entry.intersectionRatio > 0.1) {
                entry.target.animate();
                observer.unobserve(entry.target)

            }
        });
    }

    const observer = new IntersectionObserver(intersectionHandler, {});

    [...document.querySelectorAll(".js-stat-number")].forEach(el => {
        initCircleStat(el);
        //setup observers skip the disabled animation elements
        if (!el.getAttribute("data-disable-animation")) {
            observer.observe(el);
        }
    });
});