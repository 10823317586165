import $ from 'jquery';
import { leftArrowClick, pageNumberClick, rightArrowClick } from './pagination';

function showHematologistDetails(mcid) {
    $('.lds-css').show();

    $.ajax({
        url: "/getHtml/HematologistDetails/" + mcid,
        type: "GET",
        context: this,
        traditional: true,
        success: function (data) {
            $('#find-a-hematologist').hide();
            $('#hematologists-list-container').hide();
            $('#hematologist-details').html(data);
            $('#hematologist-details-container').show();
            $('#hematologists-not-found').hide();
            $('.lds-css').hide();
        },
        error: function (data) {
            console.error("Failed to GET /getHtml/HematologistDetails/" + mcid + ". Data: ", data);
            $('.lds-css').hide();
        }
    });
}

function onSubmitButtonClickReset() {
    //reset current page 
    $('input[name="AjaxModel.CurrentPage"]').val(1);
    console.log($('input[name="AjaxModel.CurrentPage"]').val());
    window.location.hash = "";        
}

function updateResultsAndPagerUI(data) {
    if (data.TotalSize === 0) {
        $('#hematologists-not-found').show();
        $('#hematologists-not-found').removeAttr('hidden');
        $('#hematologists-list').html("");
        $('#hematologists-list-container #paging').html("");
        $('#hematologists-list-container #paging').hide();
    } else {
        $('#hematologists-not-found').hide();
        $('#hematologists-not-found').attr('hidden', true);
        $('input[name="AjaxModel.TotalItems"]').val(data.TotalSize);
        $('input[name="AjaxModel.TotalPages"]')
            .val(Math.ceil(data.TotalSize / $('input[name="AjaxModel.PageSize"]').val()));
        $('#hematologists-list').html(data.Html);
        $('#hematologists-list-container #paging').html(data.PagerHtml);
        $('#hematologists-list-container #paging').show();
    }
}

window.ASH = window.ASH || {};
window.ASH.FahSubmissionRequestAlreadyPending = false;
const updateResultsAndPager = (currentPage) => {
    if (window.ASH.FahSubmissionRequestAlreadyPending) {
        return;
    }
    window.ASH.FahSubmissionRequestAlreadyPending = true;

    $('input[name="AjaxModel.CurrentPage"]').val(currentPage);
    var postData = $('#form0').serialize();
    $.ajax({
        url: '/api/Forms/FindAHematologistSubmit',
        type: 'POST',
        context: this,
        traditional: true,
        data: postData,
        success: function (data) {
            updateResultsAndPagerUI(data);
            $('.lds-css').hide();
        },
        error: function (data) {
            console.error("FAH form submission failed. Data:", data);
            $('.lds-css').hide();
        },
        beforeSend: function () {
            $('.lds-css').show();
        },
        timeout: 60 * 1000 // 1 minute
    })
    .always(() => {
        window.ASH.FahSubmissionRequestAlreadyPending = false;
    });
};

function updateState(ev) {
    $('input[name="AjaxModel.State"]').val(ev.value);
}

$(function () {
    const defaultCountryValue = $('input#defaultCountry').val();
    const stateFormFields = $('.state-form-field');
    // Hide all State Form Fields
    if (stateFormFields !== null && stateFormFields !== undefined) {
        stateFormFields.hide();
    }
    // Show State Form Field based on Default Country Value
    if (defaultCountryValue !== null && defaultCountryValue !== undefined && defaultCountryValue.length !== 0) {
        const defaultCountryStates = $(".state-form-field[data-country-id='" + defaultCountryValue + "']");
        if (defaultCountryStates !== null && defaultCountryStates !== undefined && defaultCountryStates.length !== 0 && defaultCountryStates.get(0) !== null &&
            defaultCountryStates[0] !== undefined) {
            defaultCountryStates[0].style = "display: block";
        }
    }

    // Hide Not Found Message
    $('#hematologists-not-found').hide();
    // Hide Pager
    $('#hematologists-list-container #paging').hide();
    // Hide Details Container
    $('#hematologist-details-container').hide();

    $("#hematologists-list").on('click', '.show-hematologist-details', function (e) {
        e.preventDefault();
        var clickedHematologistLink = this;
        showHematologistDetails(clickedHematologistLink.getAttribute("data-mcid"));
    });

    $('#hematologist-details-container').on('click', '.return-to-hematologist-results', function (e) {
        e.preventDefault();
        $('#find-a-hematologist').show();
        $('#hematologists-list-container').show();
        $('#hematologist-details-container').hide();
    });

    $('#AjaxModel_Country').on('change', function () {
        // Hide all State Form Fields
        if (stateFormFields !== null && stateFormFields !== undefined) {
            stateFormFields.hide();
        }
        // Show States based on current Country value
        const countryValue = $(this).find(":selected").data('country-select-id');
        if (countryValue === null || countryValue === undefined || countryValue.length === 0) {
            return null;
        }

        const countryStates = $(".state-form-field[data-country-id='" + countryValue + "']");
        if (countryStates === null || countryStates === undefined || countryStates[0] === null || countryStates[0] === undefined) {
            return null;
        }
        countryStates[0].style = "display: block";
    });

    $('#hematologists-list-container')
        .off('click')
        .on('click', '.changePage', function (ev) {
            pageNumberClick(ev, updateResultsAndPager);
        })
        .on('click', '.leftArrow', function () {
            leftArrowClick(updateResultsAndPager);
        })
        .on('click', '.rightArrow', function () {
            rightArrowClick(updateResultsAndPager);
        });

    window.updateFAHResultsAndPagerUI = updateResultsAndPagerUI;
    window.updateFAHState = updateState;
    window.onSubmitButtonClickReset = onSubmitButtonClickReset;
});
