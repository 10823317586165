import $ from 'jquery';

$(document).ready(function(){

    console.log("Page has just loaded.");

    // Flag to determine if a link click is local or external.
    window.localLinkClicked = true;

    // flag for the refresh key [F5]
    var refreshKey=false;

    // Link click handler.
    $("a").on("click", function(e) {

        // Get the link URL
        var url = $(this).attr("href");
        var ref = $(this).attr("rel");
        if(ref && ref.indexOf('ext') != -1){       
            console.log("Link clicked. URL: "+url);

            var host = document.location.host;
            var regex = new RegExp(/^(http)(s?):\/\// + host );
            // Check if the link is relative to your domain:
            // This regexp (regular expression) takes in account secured and unsecured domains (http:// and https://)
            if ( !/^(http)(s?):\/\//.test(url) || regex.test(url) ) {
                window.localLinkClicked = true;
            }else{
                window.localLinkClicked = false;
            }
            console.log("Local click ? " + window.localLinkClicked);
            const body = document.querySelector('body');
            const warningText = body.dataset.exitWarning;
            if(warningText && window.localLinkClicked){
                var leave = confirm(warningText);
                if(leave){
                    refreshKey=true;
                }else{
                    e.preventDefault();
                    console.log("User has cancelled his navigation.")
                }
            }
        }
        else{
            window.localLinkClicked = true;
        }
    });


    // On beforeunload event handler.
    $(window).on('beforeunload', function(e) {
        if(typeof(e)!="undefined" && e.target.tagName!="undefined" && (!window.localLinkClicked) ){
            return true;
        }

    });
});