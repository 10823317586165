import $ from 'jquery';

const navbar = document.getElementById('navbar');
const topnav = document.getElementById('top-nav');
const sidebar = document.getElementById('navbarCollapse');

if (navbar) {
  const sticky = navbar.offsetTop;
  const checkStickyNav = () => {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add('sticky');
      topnav.classList.add('sticky-top');
      sidebar.classList.add('sticky-side');
    } else {
      navbar.classList.remove('sticky');
      topnav.classList.remove('sticky-top');
      sidebar.classList.remove('sticky-side');
    }
  };

  window.onscroll = () => {
    checkStickyNav();
  };

  // Slideout Menu
  $('.nav-item__side-menu .nav-toggle').on('click', () => {
    $('.nav-item__side-menu').toggleClass('nav-toggle__open');
    $('.side-menu-container').toggleClass('navbar-open');
  });

  // Secondary Menu
  $('.nav-item--has-child').on('click', () => {
    $('.nav-item--has-child').addClass('active');
    $('body').addClass('show-secondary-menu');
  });

  $('.nav-link--back').on('click', (e) => {
    e.stopPropagation();
    $('body').removeClass('show-secondary-menu');
    setTimeout(() => {
      $('.nav-item--has-child').removeClass('active');
    }, 500);
  });
}
