
import $ from 'jquery';

$('.search-label').on('click', (e) => {
  $('.search-form .form-group').addClass('sb-search-open');
  $('.search-form .form-group input[type="text"]').focus();
  $('.search-label').hide();
  e.stopPropagation();
});

$(document).on('click', (e) => {
  if ($('.search-form .form-control').length > 0) {
    if (
      !$(e.target).hasClass('form-control') &&
      $(e.target).is('#search') === false &&
      $('.search-form .form-control').val().length === 0
    ) {
      $('.search-form .sb-search-open').css('border', '2px solid white');
      $('.search-form .form-group').removeClass('sb-search-open');
      $('.search-label').show();
    }
  }
});

$('.search-form .form-control-submit').click((e) => {
  $('.search-form .form-control').each(() => {
    if ($('.search-form .form-control').val().length === 0) {
      e.preventDefault();
      $('.search-form .sb-search-open').css('border', '2px solid red');
    }
  });
});

$('.close-search-label').on('click', (e) => {
  $('.search-form .sb-search-open').css('border', '2px solid white');
  $('.search-form .form-group').removeClass('sb-search-open');
  $('.search-form .form-group input[type="text]').blur();
  $('.search-label').show();
  e.stopPropagation();
});
