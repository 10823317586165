/* Initialize Select component */

import createSelect from "./SelectFactory";

const initSelect = () => {
  const selects = [...document.querySelectorAll(".js-select")];


  selects.forEach((element) => {
    /* Component logic */
    if (element.getAttribute("data-init")) return false;
    element.setAttribute("data-init", true);

    const {
      dropdownListWrapper,
      dropdownToggle,
      handleKeyDown,
      toggleDropdown,
      closeDropdown,
    } = createSelect(element);

    dropdownToggle.addEventListener("click", (e) => {
      toggleDropdown();
    });

    document.addEventListener("click", (e) => {
      closeDropdown(e);
    });

    dropdownListWrapper.addEventListener("keydown", (e) => handleKeyDown(e));
  });
};

document.addEventListener("DOMContentLoaded", function () {
  initSelect();
});


export default initSelect;
