import $ from 'jquery';
import 'bootstrap';

function checkAccordionChildExpandedState(accordion) {
  const $cards = accordion.find('.card-header');
  const expandedCards = [];

  $cards.each((i, e) => {
    const $card = $(e);
    const expanded = $card.attr('aria-expanded');

    if ($card.hasClass('collapsing')) return;

    if (expanded === 'true') {
      expandedCards.push($card);
    }
  });

  if (expandedCards.length > 0) {
    accordion.attr('data-expanded-child', true);
  } else {
    accordion.attr('data-expanded-child', false);
  }
}
export default function initAccordion() {
  $('.accordion__button--open').on('click', (e) => {
    const $current = $(e.currentTarget);
    var $accordion = $current.closest('.accordion');
    var $cards = $accordion.find('.collapse');

    checkAccordionChildExpandedState($accordion);

    $accordion.find('.accordion__button--close').attr('aria-expanded', true);
    $current.attr('aria-expanded', true);

    $cards.each((_, e) => $(e).collapse('show'));

    $accordion.attr('data-expanded-child', true);
  });

  $('.accordion__button--close').on('click', (e) => {
    const $current = $(e.currentTarget);
    var $accordion = $current.closest('.accordion');
    var $cards = $accordion.find('.collapse');

    checkAccordionChildExpandedState($accordion);

    $accordion.find('.accordion__button--open').attr('aria-expanded', false);
    $current.attr('aria-expanded', false);

    $cards.each((_, e) => $(e).collapse('hide'));

    $accordion.attr('data-expanded-child', false);
  });

  $('.accordion').on('shown.bs.collapse', (e) => {
    checkAccordionChildExpandedState($(e.currentTarget));
  });

  $('.accordion').on('hidden.bs.collapse', (e) => {
    checkAccordionChildExpandedState($(e.currentTarget));
  });
}

$(document).ready(initAccordion);
