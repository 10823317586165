


const initCarousel = () => {
    const swiperEls = document.querySelectorAll('.js-card-carousel');
    if (swiperEls) {
        [...swiperEls].forEach(swiperEl => {
            if (swiperEl) {
                Object.assign(swiperEl, {
                    a11y: true,
                    loop: true,
                    lazy:true,
                    observer: true,
                    observeslidechildren: true,
                    resizeObserver:true,
                    updateOnWindowResize:true,
                    spaceBetween: 0,
                    keyboard: true,
                    on: {
                        init: () => swiperEl.classList.add("initialized")
                    },
                    onSwiper: swiper => {
                         setTimeout(() => {
                            swiper.update();
                        }, 100);
                    },
                    navigation: {
                        enabled: true,
                        nextEl: swiperEl.querySelector(".js-carousel-next"),
                        prevEl: swiperEl.querySelector(".js-carousel-prev")
                    },
                    pagination: {
                        enabled: true,
                        clickable: true,
                        el: swiperEl.querySelector(".js-carousel-pagination")
                    },
                    breakpointsBase: "container",
                    breakpoints: {
                        0: {
                            slidesPerView: "auto",
                            slidesPerGroup: 1,
                            centeredSlides: true,

                        },
                        768: {
                            slidesPerView: 3,
                        }
                    }
                });
                swiperEl.initialize();

            }
        })
    }

};


document.addEventListener("DOMContentLoaded", function () {
    initCarousel();
});