import $ from 'jquery';

// person card functionality
$('.person--expandable').on('click', () => {
  $(this)
    .toggleClass('active')
    .siblings('.person--expandable')
    .removeClass('active')
    .children('.person__expand')
    .slideUp();
  $(this).children('.person__expand').slideToggle();
});
