import '../styles/Site.scss';
import './components/Swiper/Swiper.render'
import './components/CardCarousel/CardCarousel.render';
import './components/QuoteCarousel/QuoteCarousel.render';
import './components/HorizontalTabbedComponent/HorizontalTabbedComponent.render';
import './components/ScrollContainer/ScrollContainer.render';
import './components/Select/Select.init.js';
import './components/ImageGallery/ImageGallery.render.jsx';
import './components/CircleStat/CircleStat.render.js';
import './components/FeaturedPromo/FeaturedPromo.render.js';

import $ from 'jquery';
// import 'jquery-validation';
// import 'jquery-validation-unobtrusive';
// import 'jquery-ajax-unobtrusive';
import 'bootstrap';
// import 'jquery-validation-unobtrusive-bootstrap';
import 'element-closest';
import 'sizzle';
import 'js-cookie';
// @TODO: Remove handlebars
import Handlebars from 'handlebars';
import { registerHelpers } from './HandlebarsHelpers';
registerHelpers(Handlebars);
import './form.tracking';
import './form.validate';
import './Modal';
import './Authentication';
import './welcomescreen';
import './ExitWarning';
import './GeneSearch';
import './sticky-nav';
import './navigation';
import './breadcrumbs';
import './expandable-card';
import './file-upload';
import './tooltip';
import './inner-content-card-tabs';
import './pagination';
import './ContextualFilter';
import './FindAHematologist';
import './related';
import './search';
import './select2';
import './SearchListWidget';
import './tabs';
import './slick-carousel';
import './card-navigator';
import './Announcement';
import './accordion';
