import $ from "jquery";
import 'bootstrap';
import SlickCarousel from './slick-carousel';

// show disabled arrows
function updateCardNavigation(sliderEl) {
  const arrows = $(sliderEl).find('.slick-arrow');
  if (arrows.length === 0) {
    $('.carousel-navigation').append(
      `<button class="carousel-control-prev slick-disabled" type="button" aria-disabled="true">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
      </button>
      <button class="carousel-control-next slick-disabled" type="button" aria-disabled="true">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
      </button>`
    );
  } else {
    $('.carousel-navigation').children().remove();
  }
}

export default function initCardNavigator() {
  // init slick carousel
  const sliderEls = $('.js-slider');
  sliderEls.each((index) => {
    const sliderEl = sliderEls[index];
    new SlickCarousel(sliderEl); // eslint-disable-line no-new
  });

  updateCardNavigation(sliderEls[0]);

  $('.dropdown-menu button').on('click', (e) => {

    // Set button dropdown label
    $('#card-navigation__dropdown-button').text(e.target.innerText);

    // update slick carousel
    sliderEls.each((index) => {
      const sliderEl = sliderEls[index];
      const slider = $(`#${sliderEl.id}`);
      slider.slick('refresh');
    });
  });

  $('.card-navigation .btn-link').on('click', (e) => {
    const $this = $(e.currentTarget);
    const activeCardBodyId = $this.attr('data-target');
    const $activeCardBody = $(activeCardBodyId);
    const $cardNavigation = $this.closest('.card-navigation');
    const $btnLinks = $($cardNavigation).find('.btn-link');

    // update slick carousel
    sliderEls.each((index) => {
      const sliderEl = sliderEls[index];
      const slider = $(`#${sliderEl.id}`);
      slider.slick('refresh');

      if (sliderEl.id === `cardNavigator_${activeCardBodyId.slice(1)}`) {
        updateCardNavigation(sliderEl);
      }
    });

    if ($this.attr('aria-expanded') === 'true') {
      return;
    }

    // reset card navigation nav open state
    $btnLinks.each((_, el) => $(el).attr('aria-expanded', false));

    // set active card navigation nav open state
    $this.attr('aria-expanded', true);

    // show the active carousel
    $($activeCardBody).collapse('show');
  });
}

$(document).ready(initCardNavigator);
