import $ from 'jquery';

export default function initBreadcrumbs() {
  $('.breadcrumb-dropdown-button').on('click', () => {
    $(this).toggleClass('active');
    $('.breadcrumb-dropdown-menu').toggleClass('active');
  });
}

$(document).ready(initBreadcrumbs);
