import $ from 'jquery';

$(document).ready(function () {

    $('#modalDEI').modal();

    $(".modal").on('hidden.bs.modal', function (e) {
        var modal = e.currentTarget;

        var embedDiv = modal.getElementsByClassName("embed-responsive");

        if (embedDiv !== null && embedDiv !== undefined && embedDiv[0] !== null && embedDiv[0] !== undefined) {

            var iFrame = embedDiv[0].getElementsByTagName("iframe");

            if (iFrame !== null && iFrame !== undefined && iFrame[0] !== null && iFrame[0] !== undefined) {
                iFrame[0].setAttribute("src", iFrame[0].getAttribute("src"));
            }
        }
    });
});

