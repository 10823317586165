/* Initialize Video component */

function addScript(callback = () => {}) {
    if (!window?.YT?.Player) {
      const ytapi =
        "https://www.youtube.com/iframe_api?callback=videoComponentSetup";
      const tag = document.createElement("script");
      tag.onload = () => callback();
      tag.src = ytapi;
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      document
        .getElementsByTagName("script")[0]
        .parentNode.insertBefore(tag, firstScriptTag);
    } else {
      callback();
    }
  }
  
  function onPlayerReady(event) {
    event.target.playVideo();
  }
  
  const initVideo = (el) => {
    //skip just using an iframe
    if (el.querySelector(".js-video-iframe")) return;
    const playBtn = el?.querySelector(".js-video-poster");
    //if play button doesnt exist
    if(!playBtn) return;
    const embedId = playBtn?.getAttribute("data-src");

    const setup = () => {
      if (playBtn && embedId) {
        playBtn.addEventListener("click", (e) => {
          const uid = e.target.getAttribute("data-id");
          if (embedId) {
            document.getElementById(uid).classList.add("is-active");
  
            new YT.Player(uid, {
              videoId: embedId,
              playerVars: {
                playsinline: 1,
              },
              events: {
                onReady: onPlayerReady,
              },
            });
          }
        });
      }
    };
  
    addScript(setup);
  };

  document.addEventListener("DOMContentLoaded", function () {
    [...document.querySelectorAll(".js-featured-promo")].forEach(el=>initVideo(el))
    // initHorizontalTabComponent();
  });

  export default initVideo;
  