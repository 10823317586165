import $ from 'jquery';

$(function() {
    $("#LogoutLink").click(function (e) {
        e.preventDefault();
        $('.lds-css').show();
        $.ajax({
            url: "/api/Account/Logout",
            data: {},
            context: this,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: function success(data) {
                window.location.reload(true);
                $('.lds-css').hide();
            },
            error: function error(data) {
                alert("Unable to Logout");
                console.log("error", data);
                $('.lds-css').hide();
            }
        });
    });
});