/*
  tab.js
  looks class '.tabs' and adds event listeners for that group of tabs for click / keyboard arrow keydown events
 */


import $ from 'jquery';

export default function initTabs() {
  const selectors = {
    tabListItem: '.tabs__list-item',
    tabLink: '.tabs__link',
    tabList: '.tabs__list',
    tabPanel: '.tab-panel',
  };

  const classes = {
    activeTabListItem: 'tabs__list-item--active',
  };

  function getElementsMaxHeight(elements) {
    var maxHeight = 0;
    elements.forEach((element) => {
      if (element.offsetHeight > maxHeight) {
        maxHeight = element.offsetHeight;
      }
    });
    return maxHeight;
  }

  class TabStrip {
    constructor(el) {
      this.el = el;
      this.index = 0;
      this.tabs = Array.prototype.slice.call(
        this.el.querySelectorAll(selectors.tabListItem)
      );
      this.tabLinks = this.el.querySelectorAll(selectors.tabLink);
      this.attachEventListeners();
      this.handleResize();
    }

    attachEventListeners() {
      this.tabLinks.forEach((link) => {
        link.addEventListener('click', (e) => this.handleTabClick(e));
      });

      this.el.addEventListener('keydown', (e) => this.handleTabArrow(e));
      window.addEventListener('resize', (e) => this.handleResize(e));
    }

    handleResize(e) {
      let mql = window.matchMedia('(min-width: 768px)');
      if (mql) {
        const tabActivePanelsMaxHeight = this.getActiveElementsMaxHeight(
          this.el.querySelectorAll(selectors.tabPanel)
        );
        const tabListMaxHeight = getElementsMaxHeight(
          this.el.querySelectorAll(selectors.tabList)
        );
        const tabsHeight = tabActivePanelsMaxHeight + tabListMaxHeight - 100;
        this.el.style.height = `${tabsHeight}px`;
      } else {
        this.el.style.height = 'inherit';
      }
    }

    // Click support
    handleTabClick(e) {
      e.preventDefault();
      e.stopPropagation();
      const tab = e.target.closest(selectors.tabListItem);
      const tabIndex = this.tabs.indexOf(tab);
      if (tabIndex < 0) return;
      this.setActiveItem(tabIndex);
    }

    // Accessibility support
    handleTabArrow(e) {
      e.preventDefault();
      if (
        (e.keyCode === 37 || e.keyCode === 38) &&
        this.index &&
        this.index > 0
      ) {
        this.setActiveItem(this.index - 1);
      } else if (
        (e.keyCode === 39 || e.keyCode === 40) &&
        this.index < this.tabs.length - 1
      ) {
        this.setActiveItem(this.index + 1);
      }
    }

    getActiveElementsMaxHeight(elements) {
      var activeIndex = this.index;
      var height;
      elements.forEach((element, index) => {
        if (index === activeIndex) {
          height = element.offsetHeight;
        }
      });
      return height;
    }

    setActiveItem(index) {
      this.index = index;
      const activeTab = this.tabs[index];

      // reset all tabs
      this.tabs.forEach((tab) => {
        const tabLink = tab.querySelector(selectors.tabLink);
        tab.classList.remove(classes.activeTabListItem);
        tabLink.setAttribute('aria-selected', 'false');
        tabLink.tabIndex = -1;
      });

      // set active tab
      const activeTabLink = activeTab.querySelector(selectors.tabLink);
      activeTab.classList.add(classes.activeTabListItem);
      activeTabLink.setAttribute('aria-selected', 'true');
      activeTabLink.tabIndex = 1;
      activeTabLink.focus();
      this.handleResize();
    }
  }

  const tabs = document.querySelectorAll('.tabs');
  tabs.forEach((tab) => new TabStrip(tab));
}

$(initTabs);
