/**
 * Add custom DOM listener
 *
 * @param {string} eventName - Unique event name
 * @param {function} listener - Callback function
 */
export const subscribe = (eventName, listener) =>
  document.addEventListener(eventName, listener);

/**
 * Remove custom DOM listener
 *
 * @param {string} eventName - Unique event name
 * @param {function} listener - Callback function
 */
export const unsubscribe = (eventName, listener) =>
  document.removeEventListener(eventName, listener);

/**
 * Dispatch custom event
 *
 * @param {string} eventName - Unique event name
 * @param {object} data - Your event data
 */
export const publish = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};
