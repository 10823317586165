import focusLock from 'dom-focus-lock';

const initImageGallery = el => {
    const gallery = document.getElementById(el?.dataset?.id);
    const galleryBtn = el.querySelector(".js-image-gallery-button");
    const closeBtn = gallery.querySelector(".js-close-gallery");
    const swiperEl = gallery.querySelector('.js-image-gallery-main');
    const swiperNavEl = gallery.querySelector('.js-image-gallery-thumbs');

    if (!gallery) return;

    function openGallery(e) {
        document.querySelector("body").classList.add("lock-body");
        gallery.classList.add("is-active");
        focusLock.on(gallery);
        swiperEl.swiper.slideTo(e?.target?.getAttribute("data-img") || 0);
    }

    function closeGallery() {
        document.querySelector("body").classList.remove("lock-body");
        gallery.classList.remove("is-active");
        focusLock.off(gallery);
        galleryBtn.focus();
    }

    if (swiperEl && swiperNavEl) {
        //construct data array for slide content
        const slides = [...swiperEl.querySelectorAll("swiper-slide[data-title]")].map(el => el.dataset);

        //DISPLAY SLIDER
        Object.assign(swiperEl, {
            lazy: true,
            spaceBetween: 0,
            slidesPerView: 1,
            slidesPerGroup: 1,
            a11y: { enabled: false },
            keyboard: true,
            navigation: {
                enabled: true,
                nextEl: gallery.querySelector(".js-image-gallery-prev"),
                prevEl: gallery.querySelector(".js-image-gallery-next")
            },
            // effect: "fade",
            thumbs: {
                swiper: swiperNavEl,
            },
            pagination: {
                el: gallery.querySelector(".js-slide-content-area"),
                clickable: true,
                renderBullet: function (index, className) {
                    return `<div class="image-gallery__item ${className}"><span class="image-gallery__item-content"> ${slides[index].eyebrow && `<p class="image-gallery__item-eyebrow">${slides[index].eyebrow}</p>`} ${slides[index].title && `<h3 class="image-gallery__item-title">${slides[index].title}</h3>`} ${slides[index].text && `<p class="image-gallery__item-text">${slides[index].text}</p>`} </span></div>`;
                },
            },
            allowTouchMove: false,
            observer: true,
            observeParents: true,
            autoHeight:true,
            // parallax:true,

        });

        //NAVIGATION SLIDER
        Object.assign(swiperNavEl, {
            lazy: true,
            spaceBetween: 0,
            a11y: true,
            freeMode: true,
            watchSlidesProgress: true,
            slidesPerView: "auto",
            mousewheel: true,
            spaceBetween: 8
        });

        swiperNavEl.initialize();
        swiperEl.initialize();

        // const goToSelectedSlide = (slideIndex) => {
        //     swiperEl.swiper.slideTo(isNaN(Number(slideIndex.detail)) ? 0 : Number(slideIndex.detail));
        // }
        // const updateAria = (index) => {
        //     el.querySelector(".js-horizontal-carousel-a11y").setAttribute("aria-label", `current page,${slides[index]}`);
        // }
        // // Listeners

        // swiperEl.addEventListener('swiperprogress', (event) => {
        //     const [swiper, progress] = event.detail;
        //     console.log("swiperprogress", event.detail);
        // });

        // swiperEl.addEventListener('swiperslidechange', (event) => {
        //     console.log(event);
        //     event.detail.map(Swiper => updateAria(Swiper.activeIndex));
        // });

    }

    galleryBtn.addEventListener("click", openGallery);
    closeBtn.addEventListener("click", closeGallery);
};





document.addEventListener("DOMContentLoaded", function () {
    [...document.querySelectorAll(".js-image-gallery")].forEach(el => initImageGallery(el));
});