import { newElement } from "../MultiSelect/helpers";
import { publish } from "../../utils/customEvents.js";
import { SELECT_DOM_EVENT } from "../../utils/constants.js";

const createSelect = (element, ...params) => {
  const dataLabel = element.getAttribute("data-select-label");
  const config = {
    label: dataLabel || "Select an option",
    placeholder: "Select...",
    txtSelected: "Selected",
    clearAllTxt: "Clear All",
    uid: element.id || "multi-select-field",
    ...params,
  };

  const label = newElement("label", {
    class: ["mb-2", "body-xsm", "select__label","sr-only"],
    id: `${config.uid}-label`,
    text: config.label,
  });

  let dropdown = newElement("div", { class: "select" });

  let dropdownListWrapper = newElement("div", {
    class: "select__dropdown-list-wrapper",
    tabindex: "-1",
    role: "listbox",
    "aria-labelledby": `${config.uid}-label`,
    "aria-multiselectable": false,
  });

  let dropdownList = newElement("div", {
    class: "select__dropdown-list",
  });

  let dropdownToggle = newElement("button", {
    class: "select__dropdown-toggle",
    id: `${config.uid}-button`,
    "data-target": "select",
    "aria-labelledby": `${config.uid}-label ${config.uid}-button`,
    "aria-haspopup": "listbox",
  });

  let dropdownToggleText = newElement("span", {
    class: "select__toggle-text",
    text: config.placeholder,
  });

  dropdownToggle.append(dropdownToggleText);

  const createOption = (option) => {
    //create option
     let optionElement = newElement("li", {
      class: option.selected ? ["select__option", "checked"] : "select__option",
      srcElement: option,
      role: "option",
      "aria-selected": option.selected,
      "data-value":option.value || {}
    });

    //create button
    let optionButton = newElement("button", {
      class: option.selected
        ? ["btn", "select__option-btn", "selected", "body-xsm", "w-100"]
        : ["btn", "select__option-btn", "body-xsm", "w-100"],
      value: option.selected,
      text: option.text,
    });

    //add option to dom
    optionElement.append(optionButton);

    //add event listener
    optionElement.addEventListener("click", () => {
      optionElement.classList.toggle("selected");
      optionElement.querySelector("button").value =
        !optionElement.querySelector("button").value;
      optionElement.setAttribute(
        "aria-selected",
        !optionElement.querySelector("button").value,
      );
      optionElement.srcElement.selected = !optionElement.srcElement.selected;
      element.dispatchEvent(new Event("change"));
       publish(SELECT_DOM_EVENT, optionElement.getAttribute("data-value"));
      dropdown.refresh();
    });

    option.optionElement = optionElement;
    dropdownList.append(optionElement);
  };

  element.parentNode.insertBefore(dropdown, element.nextSibling);
  dropdown.append(label);
  dropdown.append(dropdownToggle);
  dropdown.append(dropdownListWrapper);
  dropdownListWrapper.append(dropdownList);

  element.loadOptions = () => {
    dropdownList.innerHTML = "";
    Array.from(element.options).map((option) => {
      return createOption(option, element, dropdown, dropdownList);
    });

    dropdown.dropdownListWrapper = dropdownListWrapper;
    dropdown.refresh = () => {
      const selected = element.value;
      const text = element.querySelector(`[value="${selected}"]`).text;

      if (selected) {
        dropdownToggleText.innerHTML = text;
      } else {
        dropdownToggleText.innerHTML = config.placeholder;
      }
    };

    dropdown.refresh();
  };

  //add options
  element.loadOptions();

  const focusFirstInput = () => {
    if (dropdown.classList.contains("is-open")) {
      const interactive = [
        ...dropdown.dropdownListWrapper.querySelectorAll("button"),
        ...dropdown.dropdownListWrapper.querySelectorAll("input"),
      ];
      interactive[0].style.display === "none"
        ? interactive[1].focus()
        : interactive[0].focus();
    }
  };

  const toggleDropdown = () => {
    dropdown.classList.toggle("is-open");
    dropdown.refresh();
    focusFirstInput();
  };

  const closeDropdown = (e) => {
    if (!dropdown.contains(e.target)) {
      dropdown.classList.remove("is-open");
      dropdown.refresh();
    }
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    const shift = e.shiftKey;
    const ctrl = e.ctrlKey;
    const meta = e.metaKey;
    const items = [...dropdown.dropdownListWrapper.querySelectorAll("button")];
    const max = items.length - 1;
    const index = items.indexOf(document.activeElement);
    switch (key) {
      case "ArrowDown":
      case "ArrowRight":
        if (shift) {
          //down
          //TODO need to account for hidden clear
          if (index - 1 >= 0) {
            items[index - 1].focus();
          }
        } else {
          //up
          if (index + 1 <= max) {
            items[index + 1].focus();
          }
        }
        break;
      case "ArrowUp":
      case "ArrowLeft":
        if (shift) {
          //up
          if (index + 1 <= max) {
            items[index + 1].focus();
          }
        } else {
          //down
          //TODO need to account for hidden clear
          if (index - 1 >= 0) {
            items[index - 1].focus();
          }
        }
        break;
      case "Tab":
         break;
    }
  };

  return {
    config,
    label,
    dropdown,
    dropdownListWrapper,
    dropdownToggle,
    handleKeyDown,
    toggleDropdown,
    closeDropdown,
    createOption,
  };
};

export default createSelect;
