import $ from 'jquery';
import 'select2';

export default function initSelect2() {
  $('.form-field--inline select, .form-field select').select2({
    minimumResultsForSearch: Infinity,
    theme: 'ash',
    width: '100%',
  });
}

$(document).ready(initSelect2);