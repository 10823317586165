import $ from 'jquery';

function getPagingElement() {
	const paging = $('#paging');
	if (paging !== null && paging !== undefined) {
		return paging;
	}
	return null;
}

function getCurrentPage() {
	const paging = getPagingElement();
	if (paging !== null) {
		const currentPage = paging.attr('data-current-page');
		if (currentPage !== null && currentPage !== undefined && currentPage.length) {
			return currentPage;
		}
	}
	return 0;
}

export function leftArrowClick(update) {
	const currentPageNumber = parseInt(getCurrentPage(), 0);
	const decrementedPage = currentPageNumber <= 0 ? 0 : currentPageNumber - 1;
	const paging = getPagingElement();
	if (paging !== null) {
		paging.attr('data-current-page', decrementedPage);
	}
	window.location.hash = decrementedPage;
	update(decrementedPage);
}

export function rightArrowClick(update) {
	const currentPageNumber = parseInt(getCurrentPage(), 0);
	const incrementedPage = currentPageNumber + 1;
	const paging = getPagingElement();
	if (paging !== null) {
		paging.attr('data-current-page', incrementedPage);
	}
	window.location.hash = incrementedPage;
	update(incrementedPage);
}

export function pageNumberClick(ev, update) {
	const paging = getPagingElement();
	const newPage = ev.target.innerHTML;
	if (!!newPage) {
		paging.attr('data-current-page', newPage);
	}
	window.location.hash = newPage;
	update(newPage);
}
