import $ from "jquery";
import "slick-carousel";

class SlickCarousel {
  constructor(el) {
    this.el = el;

    this.init();
  }

  init() {
    const slider = $(`#${this.el.id}`);

    slider.on('afterChange', () => {
      $('.slick-next').attr('disabled', false);
      $('.slick-prev').attr('disabled', false);
      $('.slick-disabled').attr('disabled', true);
    });

    slider.slick({
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      dots: false,
      infinite: false,
      mobileFirst: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
      prevArrow: `
        <button class="slick-arrow slick-prev" type="button">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </button>`,
      nextArrow: `
        <button class="slick-arrow slick-next" type="button">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </button>`,
    });
  }
}

export default SlickCarousel;
