import $ from 'jquery';

export default function initNavigation() {
  // account popup functionality
  $('.nav-item__my-account--logged-in').on('click', (e) => {
    $(e.currentTarget).toggleClass('show-logout');
  });

  // home page scroll
  $('.header-image__scroll-down').on('click', (e) => {
    e.preventDefault();
    $('html, body').animate(
      {
        scrollTop: $('#homeAnchor').offset().top,
      },
      1000
    );
  });

  // add class to 'header-content__sidebar' if focus box exists
  $('.header-content__focus-box')
    .siblings('.header-content__sidebar')
    .addClass('header-content__sidebar--has-focus-box');

  // show "welcome" modal and add/remove custom body class for modal-specific styles
  // $('#modalWelcome').modal('show').parents('body').addClass('modal--welcome');

  // $('#modalWelcome').on('hidden.bs.modal', function (e) {
  // $('body').removeClass('modal--welcome');
  // });
}

$(document).ready(initNavigation);
