/*
  resource-tabs.js
  looks class '.tabs' and adds event listeners for that group of tabs for click / keyboard arrow keydown events
 */

import $ from 'jquery';
import 'nodelist-foreach-polyfill';

$('.inner-content-cards__cards li').on('click', () => {
  var cardId = $(this).attr('data-card');

  $(this).children('.card').addClass('card--active');
  $(this).siblings().children('.card').removeClass('card--active');
  $('#' + cardId)
    .slideDown()
    .siblings('.card-content')
    .slideUp();
});

const selectors = {
  tabListItem: '.inner-content-card-tabs__list-item',
  tabLink: '.inner-content-card-tabs__link',
  tabList: '.inner-content-card-tabs__list',
  tabPanel: '.inner-content-card-tabs__content',
  tabContent: '.card-content',
};

const classes = {
  activeTabListItem: 'card--active',
  activeTabContent: 'card-content--active',
};

function getElementsMaxHeight(elements) {
  let maxHeight = 0;
  elements.forEach((element) => {
    if (element.offsetHeight > maxHeight) {
      maxHeight = element.offsetHeight;
    }
  });
  return maxHeight;
}

class InnerContentCardTabStrip {
  constructor(el) {
    this.el = el;
    this.index = 0;
    this.tabs = Array.prototype.slice.call(
      this.el.querySelectorAll(selectors.tabListItem)
    );
    this.tabsContent = Array.prototype.slice.call(
      this.el.querySelectorAll(selectors.tabContent)
    );
    this.attachEventListeners();
    this.handleResize();
  }

  attachEventListeners() {
    this.el.addEventListener('click', (e) => this.handleTabClick(e));
    this.el.addEventListener('keydown', (e) => this.handleTabArrow(e));
    window.addEventListener('resize', (e) => this.handleResize(e));
  }

  handleResize(e) {
    let mql = window.matchMedia('(min-width: 768px)');
    if (mql) {
      const tabPanelsMaxHeight = getElementsMaxHeight(
        this.el.querySelectorAll(selectors.tabPanel)
      );
      const tabListMaxHeight = getElementsMaxHeight(
        this.el.querySelectorAll(selectors.tabList)
      );
      const tabsHeight = tabPanelsMaxHeight + tabListMaxHeight;
      this.el.style.height = `${tabsHeight}px`;
    } else {
      this.el.style.height = 'inherit';
    }
  }

  // Click support
  handleTabClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const tab = e.target.closest(selectors.tabListItem);
    const tabIndex = this.tabs.indexOf(tab);
    if (tabIndex < 0) return;
    this.setActiveItem(tabIndex);
  }

  // Accessibility support
  handleTabArrow(e) {
    e.preventDefault();
    if (
      (e.keyCode === 37 || e.keyCode === 38) &&
      this.index &&
      this.index > 0
    ) {
      this.setActiveItem(this.index - 1);
    } else if (
      (e.keyCode === 39 || e.keyCode === 40) &&
      this.index < this.tabs.length - 1
    ) {
      this.setActiveItem(this.index + 1);
    }
  }

  setActiveItem(index) {
    this.index = index;
    const activeTab = this.tabs[index];
    const activeTabLink = activeTab.querySelector(selectors.tabLink);
    const activeTabContent = this.tabsContent[index];

    // reset all tabs
    this.tabs.forEach((tab) => {
      const tabLink = tab.querySelector(selectors.tabLink);
      tabLink.classList.remove(classes.activeTabListItem);
      tabLink.setAttribute('aria-selected', 'false');
      tabLink.tabIndex = -1;
    });

    this.tabsContent.forEach((content) => {
      content.classList.remove(classes.activeTabContent);
    });

    // set active tab
    activeTabContent.classList.add(classes.activeTabContent);
    activeTabLink.classList.add(classes.activeTabListItem);
    activeTabLink.setAttribute('aria-selected', 'true');
    activeTabLink.tabIndex = 1;
    activeTabLink.focus();
    this.handleResize();
  }
}

(() => {
  const innerContentCardTabs = document.querySelectorAll(
    '.inner-content-card-tabs'
  );
  innerContentCardTabs.forEach((tab) => new InnerContentCardTabStrip(tab));
})();
