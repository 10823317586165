import $ from 'jquery';
import { leftArrowClick, pageNumberClick, rightArrowClick } from './pagination';

function getContextualFilterDiv() {
	const contextualFilterDiv = document.getElementById('contextual-filter');
	if (contextualFilterDiv !== null && contextualFilterDiv !== undefined) {
		return contextualFilterDiv;
	}
	return null;
}

function getContextualFilterDatasource() {
	const contextualFilterDiv = getContextualFilterDiv();
	if (contextualFilterDiv !== null) {
		const datasource = contextualFilterDiv.getAttribute('data-datasource-id');
		if (datasource !== null && datasource !== undefined) {
			return datasource;
		}
	}
	return null;
}

function getContextualFilterFilters() {
	$('.lds-css').show();

	const selectValues = [];
	const contextualFilterDiv = getContextualFilterDiv();
	const contextualFilterResults = getContextualFilterResults();

	if (contextualFilterDiv !== null && contextualFilterResults !== null) {

		$("#contextual-filter-applied").hide();

		const datasource = contextualFilterDiv.getAttribute('data-datasource-id');
		if (datasource !== null && datasource !== undefined) {
			const contextualFilterSelectTags = contextualFilterDiv.getElementsByTagName('select');
			if (contextualFilterSelectTags !== null &&
				contextualFilterSelectTags !== undefined &&
				contextualFilterSelectTags.length) {
				for (var i = 0; i < contextualFilterSelectTags.length; i++) {
					if (contextualFilterSelectTags[i] === null || contextualFilterSelectTags[i] === undefined) {
						continue;
					}
					if ($(contextualFilterSelectTags[i]).val() !== "select-all") {
						$("#contextual-filter-applied").show();
					}
					selectValues.push({
						key: $(contextualFilterSelectTags[i]).attr('id'),
						value: $(contextualFilterSelectTags[i]).val()
					});
				}
			}
		}
	}
	return selectValues;
}

function getContextualFilterResults() {
	const contextualFilterResults = document.getElementById('contextual-filter-results');

	if (contextualFilterResults !== null && contextualFilterResults !== undefined) {
		return contextualFilterResults;
	}
	return null;
}

function updateResultsAndPager(currentPage) {
	const contextualFilterResults = getContextualFilterResults();
	const datasource = getContextualFilterDatasource();

	if (contextualFilterResults !== null && currentPage !== null && datasource !== null) {
		const selectValues = getContextualFilterFilters();
		$.ajax({
			url: "/api/ContextualFilter/GetContextualFilterResults",
			type: 'POST',
			context: this,
			traditional: true,
			data: { currentPage: currentPage, datasource: datasource, selectValues: JSON.stringify(selectValues) },
			success: function (data) {
				if (data.error) {
					console.log(data.errorMessage);
					$('.lds-css').hide();
					return;
				}
				contextualFilterResults.innerHTML = data.filteredResults;
				$('.lds-css').hide();
			},
			error: function (data) {
				console.log("error", data);
				$('.lds-css').hide();
			}
		});
	}
}

$(function () {
	// Load initial results
	const hash = window.location.hash;
	if (!!hash) {
		const pageNum = hash.replace('#', '');
		if (!isNaN(Number(pageNum))) {
			updateResultsAndPager(Number(pageNum));
		}
	}

	const contextualFilterDiv = getContextualFilterDiv();
	const contextualFilterResults = getContextualFilterResults();

	if (contextualFilterDiv !== null && contextualFilterResults !== null) {
		$("#contextual-filter-applied").hide();

		const datasource = getContextualFilterDatasource();
		if (datasource !== null) {
			const contextualFilterSelectTags = contextualFilterDiv.getElementsByTagName('select');

			if (contextualFilterSelectTags !== null &&
				contextualFilterSelectTags !== undefined &&
				contextualFilterSelectTags.length) {
				const resetContextualFilters = () => {
					$('.lds-css').show();

					for (var i = 0; i < contextualFilterSelectTags.length; i++) {
						if (contextualFilterSelectTags[i] === null || contextualFilterSelectTags[i] === undefined) {
							continue;
						}
						$(contextualFilterSelectTags[i]).val('select-all').trigger('change');
					}

					$("#contextual-filter-applied").hide();
					updateResultsAndPager(1);
				};

				$('#contextual-filter-reset').on('click', function () {
					resetContextualFilters();
				});

				$('#contextual-filter-submit').on('click', function () {
					updateResultsAndPager(1);
				});

				$('#contextual-filter-results').on('click', '.changePage', function (ev) {
					pageNumberClick(ev, updateResultsAndPager);
				});

				$('#contextual-filter-results').on('click', '.leftArrow', function () {
					leftArrowClick(updateResultsAndPager);
				});

				$('#contextual-filter-results').on('click', '.rightArrow', function () {
					rightArrowClick(updateResultsAndPager);
				});
			}
		}
	}

});
