
import { subscribe } from "../../utils/customEvents.js";
import { SELECT_DOM_EVENT } from "../../utils/constants.js";


const initHorizontalTabComponent = el => {

  const swiperEl = el.querySelector('.js-horizontal-carousel');
  const swiperNavEl = el.querySelector('.js-horizontal-tab-carousel');


  if (swiperEl && swiperNavEl) {
    const slides = [...swiperEl.querySelectorAll("swiper-slide[data-title]")].map(el => el.getAttribute("data-title"));

  
    //DISPLAY SLIDER
    Object.assign(swiperEl, {
      spaceBetween: 0,
      debugger: true,
      a11y:{enabled:false},
      keyboard:true,
      slidesPerView:1,
      slidesPerGroup:1,
      navigation: {
        enabled: true,
        nextEl:el.querySelector(".js-horizontal-carousel-next"),
        prevEl: el.querySelector(".js-horizontal-carousel-prev")
      },
      effect:"fade",
      // thumbs: {
      //   swiper: swiperNavEl,
      // },
      pagination: {
      el: el.querySelector(".js-horizontal-carousel-pagination"),
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="horizontal-carousel-pagination-item ${className}">${slides[index]}</span>`;
      },
      },
    allowTouchMove: false,
    observer: true,
    observeParents: true,
    // parallax:true,
        
    });

    //NAVIGATION SLIDER
    Object.assign(swiperNavEl, {
      spaceBetween: 0,
      a11y:true,
      freeMode: true,
      watchSlidesProgress: true,
      slidesPerView:"auto",
      mousewheel:true,
      spaceBetween: 30,
      slideRole:"div",
      initialSlide:1,
    });
    
    swiperNavEl.initialize();
    swiperEl.initialize();

    const goToSelectedSlide = (slideIndex)=> {
      swiperEl.swiper.slideTo(isNaN(Number(slideIndex.detail))? 0 : Number(slideIndex.detail));
    }
    const updateAria = (index)=>{
      el.querySelector(".js-horizontal-carousel-a11y").setAttribute("aria-label", `current page,${slides[index]}`);
    }
    // Listeners
    subscribe(SELECT_DOM_EVENT, goToSelectedSlide);
    
  swiperEl.addEventListener('swiperprogress', (event) => {
    const [swiper, progress] = event.detail;
   });


   swiperEl.addEventListener('swiperslidechange', (event) => {
    event.detail.map(Swiper=> { if( Swiper.activeIndex+1 < Swiper.slides.length - 1){
      swiperNavEl.swiper.slideTo(Swiper.activeIndex + 1);
    }
    updateAria(Swiper.activeIndex);});
    
  });

  }
};

document.addEventListener("DOMContentLoaded", function () {
  [...document.querySelectorAll(".js-horizontal-tabbed-component")].forEach(el=>initHorizontalTabComponent(el))
  // initHorizontalTabComponent();
});