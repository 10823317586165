import $ from 'jquery';
import { leftArrowClick, pageNumberClick, rightArrowClick } from './pagination';

window.SearchListWidget = function(uniqueId) {
    const dataUniqueId = `[data-uniqueid="${uniqueId}"]`;
    const listContainer = $(`#list-rss-container${dataUniqueId}`);
    const listResults = $(`#list-rss-results${dataUniqueId}`);
    const monthFilter = $(`#list-rss-filter-month${dataUniqueId}`);
    const yearFilter = $(`#list-rss-filter-year${dataUniqueId}`);
    const resetBtn = $(`#list-rss-reset${dataUniqueId}`);
    const submitBtn = $(`#list-rss-submit${dataUniqueId}`);
    const pageSize = $("#" + uniqueId + "-PageSize");
    const selectedTags = $("#" + uniqueId + "-SelectedTags");
    const showDescription = $("#" + uniqueId + "-ShowDescription");
    const showDates = $("#" + uniqueId + "-ShowDates");
    const paging = $(`listContainer #paging`);
    const spinner = $('.lds-css');

    function updateResultsAndPager(currentPage) {
        if (!!listResults && currentPage !== null && !!pageSize) {
            var requestModel = {
                pageSize: parseInt(pageSize.val()),
                currentPage: parseInt(currentPage),
                month: monthFilter.val(),
                year: parseInt(yearFilter.val()),
                selectedTags: selectedTags.val(),
                showDescription: showDescription.val(),
                showDates: showDates.val()
            }
            $.ajax({
                url: "/webapi/Search/GetSearchListResults",
                data: requestModel,
                type: "POST",
                success: function (data) {
                    if (data.error) {
                        console.log(data.errorMessage);
                        spinner.hide();
                        return;
                    }
                    listResults.html(data.filteredResults);
                    if (data.paging === null || data.paging === 'null') {
                        paging.hide();
                    } else {
                        paging.show();
                    }
                    spinner.hide();
                },
                error: function (data) {
                    console.log("error", data);
                    spinner.hide();
                }
            });
        }
    }

    $(function () {
        updateResultsAndPager(1);

        resetBtn.on('click',function (e) {
            e.stopPropagation();
            monthFilter.val('1').trigger('change');
            yearFilter.val('1').trigger('change');
            updateResultsAndPager(1);
        });
        
        submitBtn.on('click',function (e) {
            e.stopPropagation();
            updateResultsAndPager(1);
        });

        listContainer.on('click', '.changePage', function (ev) {
            pageNumberClick(ev, updateResultsAndPager);
        });

        listContainer.on('click', '.leftArrow', function () {
            leftArrowClick(updateResultsAndPager);
        });

        listContainer.on('click', '.rightArrow', function () {
            rightArrowClick(updateResultsAndPager);
        });
    });
}
