import $ from 'jquery';

window.GeneSearchWidget = function () {
    //Properties
    var $spinner = $('.lds-css');
    var $tableBody = $('.gene-table tbody');
    var $queryGeneBtn = $('#QueryGeneBtn');
    var $queryVariantBtn = $('#QueryVariantBtn');
    //Init
    $(document).ready(function () {
    });
    var queryGeneName = function (geneName) {
        $.ajax({
            url: "/getHtml/Components/GeneNameSearch/" + geneName,
            type: "GET",
            success: function (tableData) {
                $tableBody.html(tableData);
            }
        });
    };
    var queryGene = function (q) {
        $.ajax({
            url: "/getHtml/Components/GeneQuery?query=" + q,
            type: "Get",
            success: function (tableData) {
                $tableBody.html(tableData);
            }
        });
    };
    $queryGeneBtn.click(function (e) {
        e.stopPropagation();
        queryGeneName($('#gene-input').val());
    });
    $queryVariantBtn.click(function (e) {
        e.stopPropagation();
        queryGene($('#variant-input').val());
    });
    $('#gene-input').keypress(function (e) {
        var key = e.which;
        if (key == 13)  // the enter key code
        {
            $queryGeneBtn.click();
            return false;
        }
    });   
}