


const initQuoteCarousel = () => {

    let cardHeight = 350;
    const swiperEls = document.querySelectorAll('.js-quote-carousel');
    if (swiperEls) {

        [...swiperEls].forEach(swiperEl => {
       
            if (swiperEl) {
                let cards = swiperEl.querySelectorAll("swiper-slide");
                let quoteCarousel = swiperEl.closest(".quote-carousel");
                  Object.assign(swiperEl, {
                    a11y: true,
                    loop: false,
                    lazy: true,
                    observer: true,
                    observeSlideChildren: true,
                    resizeObserver: true,
                    updateOnWindowResize: true,
                    keyboard: true,
                    cssMode: quoteCarousel.offsetWidth >= 797 && cards.length < 3,
                    injectStyles: [
                        `:host .swiper-css-mode .swiper-wrapper{justify-content: center;}`,
                      ],
                    on: {
                        resize:swiper=>swiper.update(),
                     
                        init: (swiper) => {
                            swiperEl.classList.add("initialized");
                            cardHeight = Math.max(...[...cards].map(el => el.scrollHeight));
                            if (cards.length <= 3) {
                                swiperEl.classList.add(`has-${cards.length}`);
                            }
                            [...cards].map(el => {
                                el.style.height = `${cardHeight}px`;
                            })
                         
                            if (swiper.allowSlideNext === false && swiper.allowSlidePrev===false) {
                                swiper.cssMode=true;
                                swiper.update();
                            } else {
                                swiper.cssMode=false;
                                swiper.update();
                            }
                     

                        }
                    },
                    onSwiper: swiper => {
                        setTimeout(() => {
                            swiper.update();
                            console.log("test");
                        }, 100);
                    },
                    navigation: {
                        enabled: true,
                        nextEl: swiperEl.querySelector(".js-quote-carousel-next"),
                        prevEl: swiperEl.querySelector(".js-quote-carousel-prev")
                    },
                    pagination: {
                        enabled: true,
                        clickable: true,
                        el: swiperEl.querySelector(".js-quote-carousel-pagination")
                    },
                    breakpointsBase: "container",
                    breakpoints: {
                        0: {
                            slidesPerView: "auto",
                            slidesPerGroup: 1,
                            // spaceBetween: 20,
                            centeredSlides: true,

                        },
                        768: {
                            slidesPerView: 3,
                            centeredSlides: false
                        }

                    }
                });

                swiperEl.initialize();

            }
        });
    }
    ;
};





document.addEventListener("DOMContentLoaded", function () {
    initQuoteCarousel();
});