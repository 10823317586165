import Cookies from 'js-cookie';
import $ from 'jquery';
import 'bootstrap';

$(document).ready(function () {
    if (Cookies.get("welcome-screen") == null) {
        console.log("welcome-screen open!");
        $('#modalWelcome').modal('show').parents('body').addClass('modal--welcome');
    }

    
    $('.close').click(function () {
        console.log("close click!");
        Cookies.set('welcome-screen', 'accepted', { expires: 30 });
        $('body').removeClass('modal--welcome');
    });

    $('.modal--welcome__close').click(function () {
        console.log("modal--welcome__close click!");
        Cookies.set('welcome-screen', 'accepted', { expires: 30 });
        $('body').removeClass('modal--welcome');
    });

});  

