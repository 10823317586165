export function registerHelpers(Handlebars) {
    Handlebars.registerHelper('isNullOrEmpty', function (conditional, options) {
        if (conditional) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('if_length', function (a, b, opts) {
        if (a > b) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    Handlebars.registerHelper('ifCurrentPage', function (currentpage, page, options) {
        if (currentpage == page) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('each_hash', function (context, options) {
        var fn = options.fn, inverse = options.inverse;
        var ret = "";

        if (typeof context === "object") {
            for (var key in context) {
                if (context.hasOwnProperty(key)) {
                    var isFirstKey = false;

                    if (Object.keys(context)[0] === key) {
                        isFirstKey = true;
                    }

                    // clone the context so it's not
                    // modified by the template-engine when
                    // setting "_key"
                    var ctx = jQuery.extend(
                        { "_key": key },
                        { "_isFirstKey": isFirstKey },
                        context[key]);

                    ret = ret + fn(ctx);
                }
            }
        } else {
            ret = inverse(this);
        }
        return ret;
    });

    Handlebars.registerHelper('previouspage', function (currentpage) {
        if (currentpage === 1) {
            return new Handlebars.SafeString('1');
        } else {
            return new Handlebars.SafeString(currentpage - 1);
        }
    });

    Handlebars.registerHelper('nextpage', function (currentpage, endpage) {
        if (currentpage === endpage) {
            return new Handlebars.SafeString(currentpage);
        } else {
            return new Handlebars.SafeString(currentpage + 1);
        }
    });

    Handlebars.registerHelper('if_eq', function (a, b, opts) {
        if (a === b) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    Handlebars.registerHelper('ifCurrentPage', function (currentpage, page, options) {
        if (currentpage == page) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });

    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

        switch (operator) {
            case '==':
                return (v1 == v2) ? options.fn(this) : options.inverse(this);
            case '===':
                return (v1 === v2) ? options.fn(this) : options.inverse(this);
            case '!=':
                return (v1 != v2) ? options.fn(this) : options.inverse(this);
            case '!==':
                return (v1 !== v2) ? options.fn(this) : options.inverse(this);
            case '<':
                return (v1 < v2) ? options.fn(this) : options.inverse(this);
            case '<=':
                return (v1 <= v2) ? options.fn(this) : options.inverse(this);
            case '>':
                return (v1 > v2) ? options.fn(this) : options.inverse(this);
            case '>=':
                return (v1 >= v2) ? options.fn(this) : options.inverse(this);
            case '&&':
                return (v1 && v2) ? options.fn(this) : options.inverse(this);
            case '||':
                return (v1 || v2) ? options.fn(this) : options.inverse(this);
            default:
                return options.inverse(this);
        }
    });
}